import {
  FETCH_SLOTS,
  FETCH_SLOTS_SUCCESS,
  FETCH_SLOTS_FAILED,
  UPDATE_SLOT,
  BULK_UPDATE_SLOTS,
} from "../store/types";
import store from "../store/store";
import { firebase } from "../config/configureFirebase";
import { onValue, update, off } from "firebase/database";

// Fetch time slots for a specific date and ride type
// Fetch time slots for a specific date, ride type, and car type
export const fetchSlots = (date, rideType, carType) => (dispatch) => {
  const { timeSlotsRef } = firebase;

  console.log(
    "fetchSlots called with date:",
    date,
    "rideType:",
    rideType,
    "carType:",
    carType
  );

  dispatch({
    type: FETCH_SLOTS,
    payload: null,
  });

  // Unsubscribe from previous listeners to avoid multiple updates
  off(timeSlotsRef(date, rideType, carType));

  // Listen to changes on the specified time slots
  console.log("Listening to changes on timeSlotsRef for:", date, rideType, carType);
  onValue(timeSlotsRef(date, rideType, carType), async (snapshot) => {
    console.log("Snapshot received:", snapshot.val());

    if (snapshot.val()) {
      const data = snapshot.val();
      const timeSlots = Object.keys(data).map((formattedTime) => ({
        time: formattedTime,
        available: data[formattedTime]?.available || false,
      }));

      console.log("Using existing time slots:", timeSlots);

      dispatch({
        type: FETCH_SLOTS_SUCCESS,
        payload: timeSlots,
      });
    } else {
      console.warn("No slots found for the given combination. Generating default time slots...");

      // Generate default time slots if no data exists
      const timeSlots = {};
      for (let i = 0; i < 24 * 4; i++) {
        const hours = Math.floor(i / 4);
        const minutes = (i % 4) * 15;
        const formattedTime = `${hours % 12 || 12}:${minutes
          .toString()
          .padStart(2, "0")} ${hours < 12 ? "AM" : "PM"}`;
        timeSlots[formattedTime] = { available: false }; // Default availability
      }

      console.log("Generated new time slots:", timeSlots);

      // Save the generated time slots to the database
      try {
        await update(timeSlotsRef(date, rideType, carType), timeSlots);
        console.log("Default time slots saved successfully");

        const timeSlotsArray = Object.keys(timeSlots).map((formattedTime) => ({
          time: formattedTime,
          available: timeSlots[formattedTime].available,
        }));

        dispatch({
          type: FETCH_SLOTS_SUCCESS,
          payload: timeSlotsArray,
        });
      } catch (error) {
        console.error("Failed to save default time slots:", error);

        dispatch({
          type: FETCH_SLOTS_FAILED,
          payload: "Error generating default time slots",
        });
      }
    }
  });
};

// Update availability for a single time slot
export const editTimeSlot =
  (date, rideType, timeSlot, method) => async (dispatch) => {
    const { singleTimeSlotRef } = firebase;

    console.log(
      "editTimeSlot called with date:",
      date,
      "rideType:",
      rideType,
      "timeSlot:",
      timeSlot,
      "method:",
      method,
    );

    if (method === "Update") {
      console.log("Updating single time slot:", timeSlot.time);
      try {
        await update(singleTimeSlotRef(date, rideType, timeSlot.time), {
          available: timeSlot.available,
        });
        console.log("Time slot updated successfully:", timeSlot);
      } catch (error) {
        console.error("Failed to update time slot:", error);
      }
    }
  };

// Bulk update availability for all slots on a specific date and ride type
export const bulkUpdateSlots =
  (date, rideType, carType, isAvailable) => async (dispatch) => {
    const { timeSlotsRef } = firebase;

    const slots = {};
    for (let i = 0; i < 24 * 4; i++) {
      const hours = Math.floor(i / 4);
      const minutes = (i % 4) * 15;
      const formattedTime = `${hours % 12 || 12}:${minutes
        .toString()
        .padStart(2, "0")} ${hours < 12 ? "AM" : "PM"}`;
      slots[formattedTime] = { available: isAvailable };
    }

    try {
      await update(timeSlotsRef(date, rideType, carType), slots);
      console.log("Bulk update successful");
    } catch (error) {
      console.error("Failed to bulk update time slots:", error);
    }
  };