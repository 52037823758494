import {
    CONFIRM_BOOKING,
    CONFIRM_BOOKING_SUCCESS,
    CONFIRM_BOOKING_FAILED,
    CLEAR_BOOKING
} from "../store/types";
import { RequestPushMsg } from '../other/NotificationFunctions';
import store from '../store/store';
import { firebase } from '../config/configureFirebase';
import { formatBookingObject } from '../other/sharedFunctions';
import { get, onValue, push } from "firebase/database";

export const clearBooking = () => (dispatch) => {
    dispatch({
        type: CLEAR_BOOKING,
        payload: null,
    });
}
export const addBooking = (bookingData) => async (dispatch) => {
  const { bookingRef, settingsRef, singleUserRef } = firebase;

  dispatch({ type: CONFIRM_BOOKING, payload: bookingData });

  try {
    const settingsdata = await get(settingsRef);
    const settings = settingsdata.val();

    const data = await formatBookingObject(bookingData, settings);

    // Calculate the booking number
    const snapshot = await get(bookingRef);
    const totalBookings = snapshot.exists()
      ? Object.keys(snapshot.val()).length
      : 0;
    const booking_number = totalBookings + 1;

    // Add bookingNumber to the data
    const bookingDataWithNumber = { ...data, booking_number };

    if (bookingData.requestedDrivers) {
      const drivers = bookingData.requestedDrivers;
      Object.keys(drivers).forEach((uid) => {
        onValue(
          singleUserRef(uid),
          (snapshot) => {
            if (snapshot.exists()) {
              const user = snapshot.val();
              console.log("Driver Data:", user);
            } else {
              console.warn(`No data for UID: ${uid}`);
            }
          },
          { onlyOnce: true },
        );
      });
    }

    const res = await push(bookingRef, bookingDataWithNumber);
    const bookingKey = res.key;

    dispatch({
      type: CONFIRM_BOOKING_SUCCESS,
      payload: {
        booking_id: bookingKey,
        mainData: { ...bookingDataWithNumber, id: bookingKey },
      },
    });
  } catch (error) {
    console.error("Error:", error);
    dispatch({
      type: CONFIRM_BOOKING_FAILED,
      payload: error.code + ": " + error.message,
    });
  }

};

export const addBookingOld = (bookingData) => async (dispatch) => {

    const   {
        bookingRef,
        settingsRef,
        singleUserRef
    } = firebase;

    dispatch({
        type: CONFIRM_BOOKING,
        payload: bookingData,
    });

    const settingsdata = await get(settingsRef);
    const settings = settingsdata.val();

    let data = await formatBookingObject(bookingData, settings);
    // console.log('🔥afterformating booking',JSON.stringify(data));
    if(bookingData.requestedDrivers){
        const drivers = bookingData.requestedDrivers;
        Object.keys(drivers).map((uid)=>{

            onValue(singleUserRef(uid),  snapshot => {

                if (snapshot.exists()) {
                    console.log("User Data:", snapshot.val());
                } else {
                    console.warn("No data found for UID:", uid);
                }

                if (snapshot.val()) {
                    const pushToken = snapshot.val().pushToken;
                    const ios = snapshot.val().userPlatform == "IOS"? true: false
                    if(pushToken){
                        RequestPushMsg(
                            pushToken,
                            {
                                title: store.getState().languagedata.defaultLanguage.notification_title,
                                msg: store.getState().languagedata.defaultLanguage.new_booking_notification,
                                screen: 'DriverTrips',
                                channelId: settings.CarHornRepeat? 'bookings-repeat': 'bookings',
                                ios: ios
                            });
                     }
                 }
            }, {onlyOnce: true});
            return drivers[uid];
        })
    }
    push(bookingRef, data).then((res) => {
        var bookingKey = res.key;
        dispatch({
            type: CONFIRM_BOOKING_SUCCESS,
            payload: {
                booking_id:bookingKey,
                mainData:{
                    ...data,
                    id:bookingKey
                }
            }    
        });
    }).catch(error => {
        dispatch({
            type: CONFIRM_BOOKING_FAILED,
            payload: error.code + ": " + error.message,
        });
    });
};

