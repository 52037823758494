// store/reducers.js
import {
  FETCH_SLOTS,
  FETCH_SLOTS_SUCCESS,
  FETCH_SLOTS_FAILED,
  UPDATE_SLOT,
  BULK_UPDATE_SLOTS,
} from "../store/types";

const initialState = {
  slots: [],
  loading: false,
  error: null,
};

export const slotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SLOTS:
      return { ...state, loading: true };
    case FETCH_SLOTS_SUCCESS:
      return { ...state, slots: action.payload, loading: false };
    case FETCH_SLOTS_FAILED:
      return { ...state, error: action.payload, loading: false };
    case UPDATE_SLOT:
      return {
        ...state,
        slots: state.slots.map((slot) =>
          slot.time === action.payload.time
            ? { ...slot, available: action.payload.available }
            : slot,
        ),
      };
    case BULK_UPDATE_SLOTS:
      return {
        ...state,
        slots: Object.entries(action.payload).map(([time, { available }]) => ({
          time,
          available,
        })),
      };
    default:
      return state;
  }
};
