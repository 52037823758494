import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { useDispatch, useSelector } from "react-redux";
import { api } from "common"; // Import actions via the common module

const TimeSlotManagement = () => {
  const dispatch = useDispatch();
  const { fetchSlots, editTimeSlot, bulkUpdateSlots } = api; // Access actions
  const { slots, loading } = useSelector((state) => state.slots);
  const [selectedDate, setSelectedDate] = useState("");
  const [rideType, setRideType] = useState("");
  const [carType, setCarType] = useState("");

  const rideTypes = ["City Rides", "Rental", "Outstation"];
  const carTypes = ["Hatchback", "Sedan", "MUV"]; // New car types dropdown
  const next7Days = Array.from(
    { length: 7 },
    (_, i) =>
      new Date(Date.now() + i * 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0],
  );

  useEffect(() => {
    if (selectedDate && rideType && carType) {
      dispatch(fetchSlots(selectedDate, rideType, carType));
    }
  }, [selectedDate, rideType, carType, dispatch]);

  const handleBulkUpdate = (isAvailable) => {
    dispatch(bulkUpdateSlots(selectedDate, rideType, carType, isAvailable));
  };

  return (
    <div>
      <h2>Time Slot Management</h2>
      <div style={{ marginBottom: "20px" }}>
        <label>
          Select Date:{" "}
          <select
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
          >
            <option value="">--Select Date--</option>
            {next7Days.map((date) => (
              <option key={date} value={date}>
                {date}
              </option>
            ))}
          </select>
        </label>
        <label style={{ marginLeft: "20px" }}>
          Select Ride Type:{" "}
          <select
            value={rideType}
            onChange={(e) => setRideType(e.target.value)}
          >
            <option value="">--Select Ride Type--</option>
            {rideTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </label>
        <label style={{ marginLeft: "20px" }}>
          Select Car Type:{" "}
          <select value={carType} onChange={(e) => setCarType(e.target.value)}>
            <option value="">--Select Car Type--</option>
            {carTypes.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </label>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <MaterialTable
          title={`Time Slots for ${rideType} (${carType}) on ${selectedDate}`}
          columns={[
            { title: "Time Slot", field: "time", editable: "never" },
            {
              title: "Available",
              field: "available",
              type: "boolean",
            },
          ]}
          data={slots}
          options={{
            search: true,
            paging: false,
            sorting: true,
          }}
          editable={{
            onRowUpdate: (newData) =>
              new Promise((resolve, reject) => {
                dispatch(
                  editTimeSlot(
                    selectedDate,
                    rideType,
                    { time: newData.time, available: newData.available },
                    "Update", // The method passed here as "Update"
                  ),
                )
                  .then(() => resolve())
                  .catch((err) => {
                    console.error("Error updating time slot:", err);
                    reject(err);
                  });
              }),
          }}
        />
      )}
      <div style={{ marginTop: "20px" }}>
        <button onClick={() => handleBulkUpdate(true)}>
          Mark All as Available
        </button>
        <button onClick={() => handleBulkUpdate(false)}>
          Mark All as Unavailable
        </button>
      </div>
    </div>
  );
};

export default TimeSlotManagement;
